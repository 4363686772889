import { React, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Layout } from '../App/Layout';
import { fetchConToken } from '../../helpers/fetch';
import swal from 'sweetalert2';

function CodeVerification(props) {

  let navigate = useNavigate();

  const { uuid } = useParams()

  const [verificationCode, setVerificationCode] = useState("");
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [displayPage, setDisplayPage] = useState(true);

  // Consultar si uuid existe, además verificar en llamada a api que el uuid sea valido y que el código de verificación no haya expirado.
  useEffect(() => {
    async function fetchData() {
      try {
        const resp = await fetchConToken('reset-password/check-uuid', { uuid }, 'POST');
        const body = await resp.json();
        if(body.error === false) {
          setDisplayPage(true)
        } else {
          setDisplayPage(false)
        }
      } catch (err) {
        console.log(err)
        setDisplayPage(false)
      }
    }
    fetchData();
  }, [uuid]);


  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setDisableSubmitButton(true)

      const resp = await fetchConToken('reset-password/check-verification-code', { uuid, verificationCode }, 'POST');
      const body = await resp.json();

      if( body.error === false ) {
        swal.fire({icon: 'success', text: 'Código de verificación correcto'});
        localStorage.setItem("verificationCode", verificationCode);
        navigate("/password-confirm/"+uuid);
      } else {
        swal.fire({title: 'Error', text: body.error_msg })
        setDisableSubmitButton(false)
      }

    } catch (err) {
      swal.fire({title: 'Error', text: 'Error al verificar código. Si el problema persiste contacte al administrador.' })
      setDisableSubmitButton(false)
    }
  };

  return (
    <Layout>
      { displayPage ?
      <>
        {/* Header */}
        <div>
          <h2 className="mt-6 text-center text-2xl font-extrabold text-gray-800">Verificar Código de recuperación</h2>
          <div className="mt-2 text-center text-base text-gray-600">
            <ul>
              <li>Ingrese el código de verificación enviado a su Email.</li>
            </ul>
          </div>
        </div>

        {/* Form */}
        <form className="mt-8 space-y-6 max-w-md mx-auto" onSubmit={handleSubmit}>
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="email" className="sr-only">Ingrese Email</label>
              <input
                id="verificationCode"
                name="verificationCode"
                type="text"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                autoComplete="off"
                required
                className="appearance-none rounded-lg relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                placeholder="Ingrese código de verificación" />
            </div>
          </div>

          <div>
            <button
              type="submit"
              disabled={disableSubmitButton}
              className="cgmn-btn-green group relative w-full flex justify-center py-3 px-4 border border-transparent text-sm font-medium rounded-lg  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">

              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                <svg className="h-5 w-5 text-green-300 group-hover:text-green-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                </svg>
              </span>
              { disableSubmitButton ? 'Enviando...' : 'Validar Código' }


            </button>
          </div>
        </form>


        </>
        :
        <div>
          <h2 className="mt-6 text-center text-2xl font-semibold text-gray-500">Código de verificación expirado o no existe.</h2>
        </div>
      }

      {/* Back button */}
      <div className='w-32 mx-auto mt-10'>
          <Link to="/">
          <button
            type="button"
            className="group relative w-32 flex mx-auto justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-gray-700 hover:text-gray-800 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
            <span className="pr-2">
              <svg className="h-5 w-5 text-gray-500 group-hover:text-gray-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
              </svg>
            </span>
            Inicio
          </button>
          </Link>
        </div>


    </Layout>
  );
}

export default CodeVerification;

