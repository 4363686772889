import React from 'react';
import logo_mtemuco from '../../assets/images/logo_mtemuco.png';

export const Layout = ({ children }) => {

//const { loader, loader_msg } = useSelector( state => state.app );
let loader_msg = 'Cargando...';
let loader = false;
return (
  <div className="min-h-full w-full flex items-center justify-center py-6 px-4 sm:px-6 lg:px-8">
    <div className=" w-full h-full">
      <div>
        <img src={logo_mtemuco}  className="mx-auto h-24" alt="Logo Municipalidad de Temuco"></img>
      </div>

      {children}

      { loader ?
      <div id="preloader" className="preloader">

        <svg className="circular" viewBox="25 25 50 50">
            <circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10" />
        </svg>
        <p id="preloader_text">{ loader_msg }</p>
      </div>
      :
      null
      }
    </div>
  </div>
)};

export default Layout;
