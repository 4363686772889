import { BrowserRouter, Routes, Route } from "react-router-dom";
import Error404 from "./components/App/Error404";
import Home from "./components/Home/Home";
import ResetEmail from "./components/ResetEmail/ResetEmail";
import ResetSms from "./components/ResetSms/ResetSms";
import CodeVerification from "./components/CodeVerification/CodeVerification";
import PasswordConfirm from "./components/PasswordConfirm/PasswordConfirm";


const Router = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" >
            <Route index element={<Home />}></Route>
            <Route path="reset-email" element={<ResetEmail />}></Route>
            {/* <Route path="reset-sms" element={<ResetSms />}></Route> */}
            <Route path="code-verification/:uuid" element={<CodeVerification />}></Route>
            <Route path="password-confirm/:uuid" element={<PasswordConfirm />}></Route>
            <Route path="*" element={<Error404 />}></Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default Router;