import { React, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Layout } from '../App/Layout';
import { fetchConToken } from '../../helpers/fetch';
import swal from 'sweetalert2';
import ReCAPTCHA from 'react-google-recaptcha';


function ResetEmail() {

  let navigate = useNavigate();
  const recaptchaRef = useRef(null)
  const [email, setEmail] = useState("");
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [captchaToken, setCaptchaToken] = useState("");

  let handleSubmit = async (e) => {
    e.preventDefault();
    try {

      if(captchaToken !== "") {
        setDisableSubmitButton(true)

        const resp = await fetchConToken('reset-password-email/send-code', { email, captchaToken }, 'POST');
        const body = await resp.json();

        recaptchaRef.current.reset();

        if( body.error === false ) {
          swal.fire({icon: 'success', text: 'Código de recuperación enviado'});
          navigate("/code-verification/"+body.uuid);
        } else {
          swal.fire({title: 'Error', text: body.error_msg })
          setDisableSubmitButton(false)
        }

      }
      else{
        swal.fire({title: 'Error', text: 'Debes verificar que no eres un robot'})
      }


    } catch (err) {
      console.log(err)
      swal.fire({title: 'Error', text: 'Error al solicitar código vía Email. Si el problema persiste contacte al administrador.' })
      setDisableSubmitButton(false)
    }

  };


  const handleRecaptcha = (value) => {
    setCaptchaToken(value)
  }


  return (
    <Layout>
      {/* Header */}
      <div>
        <h2 className="mt-6 text-center text-2xl font-extrabold text-gray-800">Recuperar Contraseña vía Email</h2>
        <div className="mt-2 text-center text-base text-gray-600">
          Ingrese su email institucional y le enviaremos un código de recuperación.
        </div>
      </div>

      {/* Form */}
      <form className="mt-8 space-y-6 max-w-md mx-auto" onSubmit={handleSubmit}>
        <div className="rounded-md shadow-sm -space-y-px">
          <div>
            <label htmlFor="email" className="sr-only">Ingrese Email</label>
            <input
              id="email"
              name="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="email"
              required
              className="appearance-none rounded-lg relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
              placeholder="Ingrese Email" />
          </div>
        </div>

        {/* ReCAPTCHA */}
        <div className='max-w-md text-center'>
            <ReCAPTCHA
            ref={recaptchaRef}
            className='inline-block'
              sitekey="6Lf8fDwfAAAAAJ1XZ-J3KJszSR9G7Hzj2U8LBJ_P"
              onChange={handleRecaptcha}
            />
        </div>

        {/* CONFIRM BUTTON */}
        <div>
          <button
            type="submit"
            disabled={disableSubmitButton}
            className="cgmn-btn-green group relative w-full flex justify-center py-3 px-4 border border-transparent text-sm font-medium rounded-lg  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">

            <span className="absolute left-0 inset-y-0 flex items-center pl-3">
              <svg className="h-5 w-5 text-green-300 group-hover:text-green-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
              </svg>
            </span>
            { disableSubmitButton ? 'Enviando...' : 'Enviar código de recuperación' }


          </button>
        </div>
      </form>

      {/* Back button */}
      <div className='w-32 mx-auto mt-10'>
        <Link to="/">
        <button
          type="button"
          className=" group relative w-32 flex mx-auto justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-gray-800 hover:text-gray-900 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
          <span className="pr-2">
            <svg className="h-5 w-5 text-gray-500 group-hover:text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
              <path strokeLinecap="round" strokeLinejoin="round" d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z" />
            </svg>
          </span>
          Volver
        </button>
        </Link>
      </div>

    </Layout>
  );
}

export default ResetEmail;

