import { React } from 'react';
import { Link } from "react-router-dom";
import { Layout } from '../App/Layout';

function Home() {
  return (
    <Layout>
      {/* Header */}
      <div className='max-w-lg mx-auto'>
        <h2 className="mt-6 text-center text-2xl font-extrabold text-gray-800">Cambio de contraseña</h2>
        <div className="mt-2 text-center text-base text-gray-600">
        En la siguiente sección Usted podrá realizar el cambio de contraseña.
        </div>
      </div>

      {/* Form */}
      <form className="mt-8 space-y-6 max-w-md mx-auto" action="#" method="POST">
        <div>
          <Link to="/reset-email/">
            <button type="button" className="cgmn-btn-green group relative w-full flex justify-center py-3 px-4 border border-transparent text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                {/* Heroicon name: solid/at-symbol */}
                <svg className="h-5 w-5 text-green-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M14.243 5.757a6 6 0 10-.986 9.284 1 1 0 111.087 1.678A8 8 0 1118 10a3 3 0 01-4.8 2.401A4 4 0 1114 10a1 1 0 102 0c0-1.537-.586-3.07-1.757-4.243zM12 10a2 2 0 10-4 0 2 2 0 004 0z" clipRule="evenodd" />
                </svg>
              </span>
              Recuperar contraseña vía Email
            </button>
          </Link>
        </div>

        {/* Se deshabilita por el momento a la espera de API SMS. */}

        {/* <div>
          <Link to="/reset-sms/">
            <button type="button" className="cgmn-btn-green group relative w-full flex justify-center py-3 px-4 border border-transparent text-sm font-medium rounded-md  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                <svg className="h-5 w-5 text-green-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M7 2a2 2 0 00-2 2v12a2 2 0 002 2h6a2 2 0 002-2V4a2 2 0 00-2-2H7zm3 14a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
                </svg>
              </span>
              Recuperar contraseña vía SMS
            </button>
          </Link>
        </div>  */}


        {/* Se muestra la opción de recuperar vía SMS pero deshabilitada */}
        {/* <div>
            <button type="button" className="bg-gray-200 text-gray-400 group relative w-full flex justify-center py-3 px-4 border border-transparent text-sm font-medium rounded-md cursor-default">
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                <svg className="h-5 w-5 text-gray-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M7 2a2 2 0 00-2 2v12a2 2 0 002 2h6a2 2 0 002-2V4a2 2 0 00-2-2H7zm3 14a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
                </svg>
              </span>
              Recuperar contraseña vía SMS
            </button>
        </div> */}


      </form>
    </Layout>
  );
}

export default Home;

