import { React, useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Layout } from '../App/Layout';
import { fetchConToken } from '../../helpers/fetch';
import swal from 'sweetalert2';

function CodeVerification(props) {

  let navigate = useNavigate();

  const { uuid } = useParams()
  const verificationCode = localStorage.getItem("verificationCode");

  const [passwordError, setPasswordErr] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [passwordInput, setPasswordInput]= useState({
      password:'',
      confirmPassword:''
  })
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [displayPage, setDisplayPage] = useState(true);

  const [samAccountName, setSamAccountName] = useState("");

  // Consultar que el código de verificación sea el correcto.
  useEffect(() => {
    async function fetchData() {
      try {
        const resp = await fetchConToken('reset-password/check-verification-code', { uuid, verificationCode }, 'POST');
        const body = await resp.json();
        if(body.error === false) {
          setDisplayPage(true)
          setSamAccountName(body.data.sam_account_name)
        } else {
          setDisplayPage(false)
        }
      } catch (err) {
        console.log(err)
        setDisplayPage(false)
      }
    }
    fetchData();
  }, [uuid]);


  const handlePasswordChange =(evnt)=>{
    const passwordInputValue = evnt.target.value.trim();
    const passwordInputFieldName = evnt.target.name;
    const NewPasswordInput = {...passwordInput,[passwordInputFieldName]:passwordInputValue}
    setPasswordInput(NewPasswordInput);

}
const handleValidation= (evnt)=>{
    const passwordInputValue = evnt.target.value.trim();
    const passwordInputFieldName = evnt.target.name;
        //for password
if(passwordInputFieldName==='password'){
    const uppercaseRegExp   = /(?=.*?[A-Z])/;
    const lowercaseRegExp   = /(?=.*?[a-z])/;
    const specialCharRegExp = /(?=.*?[#?!@$%^&()*._-])/;
    const minLengthRegExp   = /.{10,}/;
    const passwordLength =      passwordInputValue.length;
    const uppercasePassword =   uppercaseRegExp.test(passwordInputValue);
    const lowercasePassword =   lowercaseRegExp.test(passwordInputValue);
    const specialCharPassword = specialCharRegExp.test(passwordInputValue);
    const minLengthPassword =   minLengthRegExp.test(passwordInputValue);
    let errMsg ="";
    if(passwordLength===0){
            errMsg="Debe ingresar una contraseña";
    }else if(!uppercasePassword){
            errMsg="Debe contener al menos una Mayúscula";
    }else if(!lowercasePassword){
            errMsg="Debe contener al menos una Minúscula";
    }else if(!specialCharPassword){
            errMsg="Debe contener al menos un caracter especial";
    }else if(!minLengthPassword){
            errMsg="Debe contener al menos 10 caracteres";
    }else{
        errMsg="";
    }
    setPasswordErr(errMsg);
    }
    // for confirm password
    if(passwordInputFieldName=== "confirmPassword" || (passwordInputFieldName==="password" && passwordInput.confirmPassword.length>0) ){

        if(passwordInput.confirmPassword!==passwordInput.password)
        {
        setConfirmPasswordError("Las contraseñas no coinciden");
        }else{
        setConfirmPasswordError("");

        }

    }
}



  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setDisableSubmitButton(true)

      // Validar que no exista error de validación. passwordError y confirmPasswordError deben estar vacíos.
      // Además, realizar una doble validación de la cantidad minima de caracteres para evitar que se valide sin ingresar datos en los input.
      if(passwordError==="" && confirmPasswordError==="" && passwordInput.password.length>10 && passwordInput.confirmPassword.length>10){
        const resp = await fetchConToken('reset-password/confirm-new-password', { uuid, password1: passwordInput.password, password2: passwordInput.confirmPassword }, 'POST');
        const body = await resp.json();

        if( body.error === false ) {
          swal.fire({icon: 'success', text: 'Contraseña actualizada correctamente.'});
          localStorage.setItem("verificationCode", "");
          navigate("/");
        } else {
          swal.fire({title: 'Error', text: body.error_msg })
          setDisableSubmitButton(false)
        }
      }else{
        swal.fire({title: 'Error', text: "Las contraseñas no cumplen los requisitos mínimos." })
        setDisableSubmitButton(false)
      }

    } catch (err) {
      swal.fire({title: 'Error', text: 'Error al realizar cambio de contraseña. Si el problema persiste contacte al administrador.' })
      setDisableSubmitButton(false)
    }
  };

  return (
    <Layout>
    { displayPage ?
      <>
      {/* Header */}
      <div className='max-w-md mx-auto'>
        <h2 className="mt-6 text-center text-2xl font-extrabold text-gray-800">Confirmar cambio de contraseña</h2>

        <div className='w-full text-center pt-2 text-gray-700'>Nombre de usuario: <b className='text-blue-700'>{samAccountName}</b></div>

        <div className="mt-6 text-left text-sm text-gray-600 ">
          <ul className="list-disc list-inside ">
            <li>Mínimo de 10 carácteres.</li>
            <li>Debe contener al menos una letra Mayúscula y una Minúscula.</li>
            <li>Debe contener al menos un simbolo especial (#?!@$%^&*.-)</li>
            <li>Debe ser diferente a la última utilizada.</li>
          </ul>
        </div>

      </div>

      {/* Form */}
      <form className="mt-8 space-y-6 max-w-md mx-auto" onSubmit={handleSubmit} autoComplete="off">
        <div className="rounded-md shadow-sm -space-y-px">
          <div>
            <label htmlFor="password" className="sr-only">Nueva Contraseña</label>
            <input  name="password"
                    type="password"
                    value={passwordInput.password}
                    onChange={handlePasswordChange}
                    onKeyUp={handleValidation}
                    placeholder="Ingrese nueva contraseña"
                    autoComplete="off"
                    autoFocus
                    className="appearance-none rounded-lg relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm" />

            {passwordError !== "" ?
            <p className="mt-1 px-2 py-1 bg-red-50  text-red-500 border border-red-100 rounded text-xs">{passwordError}</p>
            : null }

          </div>
        </div>

        <div className="rounded-md shadow-sm -space-y-px">
          <div>
            <label htmlFor="confirmPassword" className="sr-only">Confirme Contraseña</label>
            <input  name="confirmPassword"
                    type="password"
                    value={passwordInput.confirmPassword}
                    onChange={handlePasswordChange}
                    onKeyUp={handleValidation}
                    placeholder="Confirme contraseña"
                    className="appearance-none rounded-lg relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm" />

            { confirmPasswordError !== "" ?
            <p className="mt-1 px-2 py-1 bg-red-50  text-red-500 border border-red-100 rounded text-xs">{confirmPasswordError}</p>
            : null }

          </div>
        </div>

        <div>
          <button
            type="submit"
            disabled={disableSubmitButton}
            className="cgmn-btn-green group relative w-full flex justify-center py-3 px-4 border border-transparent text-sm font-medium rounded-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">

            <span className="absolute left-0 inset-y-0 flex items-center pl-3">
              <svg className="h-5 w-5 text-green-300 group-hover:text-green-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
              </svg>
            </span>
            { disableSubmitButton ? 'En proceso...' : 'Confirmar' }


          </button>
        </div>
      </form>

      </>
        :
        <div>
          <h2 className="mt-6 text-center text-2xl font-semibold text-gray-500">Código de verificación expirado o no existe.</h2>
        </div>
      }

      {/* Home back button */}
      <div className='w-32 mx-auto mt-10'>
          <Link to="/">
          <button
            type="button"
            className="group relative w-32 flex mx-auto justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-gray-700 hover:text-gray-800 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
            <span className="pr-2">
              <svg className="h-5 w-5 text-gray-500 group-hover:text-gray-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
              </svg>
            </span>
            Inicio
          </button>
          </Link>
        </div>


    </Layout>
  );
}

export default CodeVerification;

