import React from 'react';
import { Layout } from './Layout';

export const Error404 = () => {

  return (
    <div className='lg:flex h-full w-full'>
      <Layout>
        <div className=" h-full w-full flex flex-wrap content-center">
          <span className=" text-2xl font-semibold mx-auto my-auto text-gray-500">
            Acceso Denegado
          </span>
        </div>
      </Layout>
    </div>
  )
}
export default Error404;